import React from "react"
import { Link } from "gatsby"
import PopupImage from "../images/static-only/breast-is-best-popup.jpg"
 
class NutritionPledgeModal extends React.Component {
  componentDidMount() {
    this.modalCheck()
  }

  modalCheck () {
    if ((document.cookie.match('accept-modal') == null) && (document.cookie.match('accept-cookies') != null)) {
      document.querySelector('.js-modal').classList.add('-modal-active')
      setTimeout(function() {
        document.body.classList.add('-modal-active')       
      },200);
    }
  }



  render() {
    return(
      <div className="modal js-modal">
        <span className="modal__close js-modal-close" onClick={closeModal}><i className="fas fa-times"></i></span>
        
        <div className="modal-inner">
          <img src={PopupImage} alt="Mother breast feeding her baby" className="modal-inner__image" />

          <div className="modal-inner-content">
            <span className="modal-inner-content__title">Our Nutrition <strong>Pledge</strong></span>
            <p>We recognise that the right nutrition during the first 1000 days (a period from conception up to two years of age) has a critical impact on a child's ability to learn and thrive and provides the essential building blocks for brain development, healthy growth and a strong immune system.</p>
            <p>We therefore support and promote the recommendations of the World Health Organisation (WHO) for exclusive breastfeeding in the first six months of life, and the introduction of safe, age-appropriate and nutritious complementary foods thereafter. Further, we advocate continued breastfeeding up to two years of age and beyond.</p>
            <p>For mothers who are not able to, or choose not to breastfeed, our role is to provide the highest quality products that help meet the nutritional needs of mothers, infants and children.</p>
            <Link to="/our-nutrition-pledge" className="cta -gold-gradient js-modal-cta" onClick={modalCta}>Read more</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default NutritionPledgeModal

function closeModal() {
  document.cookie = 'accept-modal=true'
  var modal = document.querySelector('.js-modal')
  modal.classList.remove('-modal-active')
  modal.addEventListener('transitionend', function() {
    document.body.classList.remove('-modal-active')
  })
}

function modalCta(e) {
  e.preventDefault()
  
  document.cookie = 'accept-modal=true'

  window.location.href = e.target.href
}