import React from "react"
import { Link } from "gatsby"
// import UKFlag from "../images/icons/UK_flag@3x.png"
// import ChinaFlag from "../images/icons/China_flag@3x.png"

class Footer extends React.Component {
  render() {
    return(
      <footer className="site-footer">
        <div className="site-footer__options">
          <span title="language selector" className="language-selector" onClick={toggleLanguageSelector}>
            EN
            <div className="language-selector__inner">
              <Link className="english language-choice -active" to="/home">EN</Link>
              <Link className="chinese language-choice" to="/zh">中文</Link>
            </div>
          </span>

          <ul className="site-navigation__supporting">
            <li><Link to="/terms-of-use">Terms of Use</Link></li>
            <li><Link to="/privacy-notice">Privacy Notice</Link></li>
          </ul>
        </div>

        <nav className="site-footer-navigation" role="navigation" aria-label="navigation">
          <ul className="site-footer-navigation__main">
            <li><Link to="/event-experience">Event Experience</Link></li>
            <li><Link to="/tips-for-flying">Flying Tips</Link></li>
            <li><Link to="/our-nutrition-pledge">Our Nutrition Pledge</Link></li>
            <li><Link to="/visit-us">Visit Us</Link></li>
            <li className="js-shop-now" onClick={toggleShopNow}>Shop Now</li>
          </ul>
        </nav>
      </footer>
    )
  }
}

export default Footer

function toggleLanguageSelector() {
  document.querySelector('.site-footer .language-selector').classList.toggle('-language-selector-active')
  document.querySelector('.site-footer .language-selector__inner').classList.toggle('-language-selector-active')
}

function toggleShopNow(e) {
  document.querySelector('.shop-now-toggle.-touchscreen').classList.toggle('-open')
  document.body.classList.toggle('-shop-now-active')
}