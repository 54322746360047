import React from "react"
import QRCodeCanada from "../images/icons/QR_Code_Canada.png"
import QRCodeChina from "../images/icons/QR_Code_China.jpeg"
import QRCodeHK from "../images/icons/QR_Code_HK.png"
import QRCodeIndonesia from "../images/icons/QR_Code_Indonesia.png"
import QRCodeMalaysia from "../images/icons/QR_Code_Malaysia.png"
import QRCodeSingapore from "../images/icons/QR_Code_Singapore.png"
import QRCodeTaiwan from "../images/icons/QR_Code_Taiwan.png"
import QRCodeThailand from "../images/icons/QR_Code_Thailand.png"
import QRCodeUS from "../images/icons/QR_Code_US.png"
import QRCodeIndia from "../images/icons/QR_Code_India.png"

class ShopNowTouchscreen extends React.Component {
  render() {
    return(
      <div className="menu-button -blue-gradient shop-now-toggle -touchscreen">
        <div className="shop-now__background"></div>
        <div className="js-shop-now-touchscreen" onClick={toggleShopNow}></div>
        <span className="shop-now-toggle__button-text">Shop Now</span>

        <div className="shop-now__inner">
          <p>
            <i className="fas fa-map-marked-alt"></i>
            <br />
            Choose your
            <br />
            <strong>shipping destination</strong>
          </p>
          <span className="help-text">
            Scroll to find your shipping destination
          </span>

          <div className="shipping-destinations">
            <div className="shipping-destinations__item">
              <input type="radio" id="destChinaTouchscreen" name="destinationsTouchscreen" value={QRCodeChina} onChange={updateCta} defaultChecked />
              <label htmlFor="destChinaTouchscreen">Mainland China, Greater China</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destUSTouchscreen" name="destinationsTouchscreen" value={QRCodeUS} onChange={updateCta} />
              <label htmlFor="destUSTouchscreen">US</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destCanadaTouchscreen" name="destinationsTouchscreen" value={QRCodeCanada} onChange={updateCta} />
              <label htmlFor="destCanadaTouchscreen">Canada</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destHongKongTouchscreen" name="destinationsTouchscreen" value={QRCodeHK} onChange={updateCta} />
              <label htmlFor="destHongKongTouchscreen">Hong Kong, Greater China</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destTaiwanTouchscreen" name="destinationsTouchscreen" value={QRCodeTaiwan} onChange={updateCta} />
              <label htmlFor="destTaiwanTouchscreen">Taiwan, Greater China</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destSingaporeTouchscreen" name="destinationsTouchscreen" value={QRCodeSingapore} onChange={updateCta} />
              <label htmlFor="destSingaporeTouchscreen">Singapore</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destMalaysiaTouchscreen" name="destinationsTouchscreen" value={QRCodeMalaysia} onChange={updateCta} />
              <label htmlFor="destMalaysiaTouchscreen">Malaysia</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destIndonesiaTouchscreen" name="destinationsTouchscreen" value={QRCodeIndonesia} onChange={updateCta} />
              <label htmlFor="destIndonesiaTouchscreen">Indonesia</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destThailandTouchscreen" name="destinationsTouchscreen" value={QRCodeThailand} onChange={updateCta} />
              <label htmlFor="destThailandTouchscreen">Thailand</label>
            </div>
            <div className="shipping-destinations__item">
              <input type="radio" id="destIndiaTouchscreen" name="destinationsTouchscreen" value={QRCodeIndia} onChange={updateCta} />
              <label htmlFor="destIndiaTouchscreen">India</label>
            </div>
          </div>
          <span className="help-text">
            You will be redirected to a site that may not be intended for residents of this country.
          </span>

          <span className="help-text -mainland-china-text">Airport exclusive discount at checkout*</span>
          <span className="help-text -other-countries">
            Airport exclusive discount at checkout*
            <br />
            Enter promo code: SCHIPHOL5
          </span>

          <div className="qr-code-wrapper">
            <span>Please use your WeChat app to scan and go to the store</span>
            <img src={QRCodeChina} alt="QR Code" className="qr-code" />
            <span className="help-text -mainland-china-text">*On select products only, applied at checkout. Excludes infant formula and medical food in most countries</span>
            <span className="help-text -other-countries">
              *On select products only, applied at checkout. Excludes infant formula and medical food in most countries
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default ShopNowTouchscreen

function toggleShopNow(e) {
  document.querySelector('.shop-now-toggle.-touchscreen').classList.toggle('-open')
  document.body.classList.toggle('-shop-now-active')
}

function updateCta(event) {
  let el = event.target

  if (el.getAttribute('id') === 'destChinaTouchscreen') {
    document.querySelector('.qr-code-wrapper span').innerHTML = 'Please use your WeChat app to scan and go to the store'

    var chinaText = document.querySelectorAll('.help-text.-mainland-china-text'), i;

    for (i = 0; i < chinaText.length; ++i) {
      chinaText[i].style.display = "block";
    }

    var otherText = document.querySelectorAll('.help-text.-other-countries'), j;

    for (j = 0; j < otherText.length; ++j) {
      otherText[j].style.display = "none";
    }
  } else {
    document.querySelector('.qr-code-wrapper span').innerHTML = 'Scan QR Code'
    
    var chinaTextTwo = document.querySelectorAll('.help-text.-mainland-china-text'), k;

    for (k = 0; k < chinaTextTwo.length; ++k) {
      chinaTextTwo[k].style.display = "none";
    }

    var otherTextTwo = document.querySelectorAll('.help-text.-other-countries'), l;

    for (l = 0; l < otherTextTwo.length; ++l) {
      otherTextTwo[l].style.display = "block";
    }
  }
  document.querySelector('.qr-code').setAttribute('src', el.getAttribute('value'))

  document.querySelector('.qr-code-wrapper').style.display = 'block'
}