import React from "react"
import { Helmet } from "react-helmet"
import Header from "./Header"
import ShopNow from "./ShopNow"
import ShopNowTouchscreen from "./ShopNowTouchscreen"
import CookieModal from "./CookieModal"
import NutritionModal from "./NutritionPledgeModal"
import MobileOptimised from "./MobileOptimised"
import Footer from "./Footer"

export default ({ children }) => {
  return (
    <div>
      <Helmet>
        <html xmlns="http://www.w3.org/1999/xhtml" lang="en" />
        <meta charSet="utf-8" />
        <title>Meet MJN</title>
      </Helmet>
      <Header />
      {children}
      <Footer />
      <ShopNow />
      <ShopNowTouchscreen />
      <CookieModal />
      <NutritionModal />
      <MobileOptimised />
    </div>
  )
}