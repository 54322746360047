import React from "react"
import { Link } from "gatsby"
import Logo from "../images/logos/MeadJohnsonLogo@3x.png"
// import UKFlag from "../images/icons/UK_flag@3x.png"
// import ChinaFlag from "../images/icons/China_flag@3x.png"

class Header extends React.Component {
  componentDidMount() {
    var navItem = document.querySelectorAll('.site-navigation li.has-children > a')
    for (var i = 0; i < navItem.length; i++) {
      navItem[i].addEventListener('click', function(e) {
        var el = e.target

        if (!el.classList.contains('-sub-nav-active')) {
          e.preventDefault()
          
          for (let sibling of el.parentNode.children) {
            if (sibling !== el) sibling.classList.add('-sub-nav-active');
          }

          el.classList.add('-sub-nav-active')
          var parent = el.parentNode
          parent.classList.add('-sub-nav-active')
        }
      })
    }
  }

  render() {
    return(
      <header className="site-header">
        <Link to="/home" className="site-logo" title="Mead Johnson Nutrition"><img src={Logo} alt="Mead Johnson Logo" /></Link>

        <div className="site-header__options">
          <span title="language selector" className="language-selector" onClick={toggleLanguageSelector}>
            EN
            <div className="language-selector__inner">
              <Link className="english language-choice -active" to="/home">EN</Link>
              <Link className="chinese language-choice" to="/zh">中文</Link>
            </div>
          </span>

          <div className="menu">
            <div className="menu-icon" onClick={headerMenuToggle}>
              <span className="middle"></span>
            </div>
          </div>

        </div>

        <nav className="site-navigation" role="navigation" aria-label="navigation">
          <ul className="site-navigation__main">
            <li onClick={toggleShopNow}><span>Shop Now</span></li>
            <li><Link to="/visit-us" title="Visit Us">Visit Us</Link></li>
            <li className="has-children">
              <Link to="/event-experience" title="Event Experience">Event Experience</Link>

              <div className="sub-navigation">
                <ul className="sub-navigation__list">
                <li><Link to="/event-experience/grazing" title="Visit one of our farms">Visit One Of Our Farms</Link></li>
                  <li><Link to="/event-experience/explore-babys-development" title="Baby's Needs">Explore Baby's Development</Link></li>
                  <li><Link to="/event-experience/learn-about-our-ingredients" title="Learn About Our Ingredients">Learn About Our Ingredients</Link></li>
                </ul>
              </div>
            </li>
            <li><Link to="/tips-for-flying" title="Tips for Flying">Tips for Flying</Link></li>
            <li><Link to="/about-us" title="About Us">About Us</Link></li>
            <li><Link to="/our-nutrition-pledge" title="Our Nutrition Pledge">Our Nutrition Pledge</Link></li>
          </ul>

          <ul className="site-navigation__supporting">
            <li><Link to="/terms-of-use" title="Terms of Use">Terms of Use</Link></li>
            <li><Link to="/privacy-notice" title="Privacy Notice">Privacy Notice</Link></li>
          </ul>
        </nav>
      </header>
    )
  }
}

export default Header

function headerMenuToggle() {
  document.querySelector('.site-header .menu .menu-icon').classList.toggle('-nav-active')
  document.querySelector('.site-navigation').classList.toggle('-nav-active')
  document.querySelector('.site-header').classList.toggle('-nav-active')
  document.body.classList.toggle('-nav-active')
}

function toggleLanguageSelector() {
  document.querySelector('.language-selector').classList.toggle('-language-selector-active')
  document.querySelector('.language-selector__inner').classList.toggle('-language-selector-active')
}

function toggleShopNow(e) {
  document.getElementsByClassName('shop-now-toggle')[0].classList.toggle('-open')
  document.body.classList.toggle('-shop-now-active')
  headerMenuToggle()
}