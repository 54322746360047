import React from "react"
import { Link } from "gatsby"
 
class CookieModal extends React.Component {
  componentDidMount() {
    this.cookieCheck()
  }

  cookieCheck () {
    // If no 'accept-cookies' cookie found, show cookie bar

    if (document.cookie.match('accept-cookies') == null) {
      if (window.innerWidth > 900) {
        document.cookie = 'accept-cookies=true'
        document.getElementsByClassName('js-cookie-consent')[0].classList.remove('-modal-active')
        document.body.classList.remove('-modal-active')
      } else {
        document.querySelector('.js-cookie-consent').classList.add('-modal-active')
        setTimeout(function() {
          document.body.classList.add('-modal-active')
        },100);
      }
    } else {
      setTimeout(function() {
        document.body.classList.remove('-modal-active')
      },100);
    }
  }

  render() {
    return(
      <div className="modal js-cookie-consent">
        <span className="modal__close js-cookie-accept" onClick={cookieClose}><i className="fas fa-times"></i></span>
        
        <div className="modal-inner">
          <div className="modal-inner-content">
            <span className="modal-inner-content__title">Cookie Consent</span>
            <p>Our site uses cookies and other technologies to tailor your experience and understand how you and other visitors use our site. By clicking "Accept all cookies" below, or by browsing our website after you have been presented with this notice, you confirm you are happy for us to use all our cookies and other technologies, or visit the Consent Tool for more options and/or to reject certain cookies. To find out more about how we use cookies and other technologies, see our Cookies Policy.</p>
            <Link to="/cookies-policy" className="cta -gold-gradient js-cookie-accept" onClick={cookieClose}>Accept all cookies</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default CookieModal

function cookieClose(e) {
  e.preventDefault()
  document.cookie = 'accept-cookies=true'
  document.getElementsByClassName('js-cookie-consent')[0].classList.remove('-modal-active')
  document.body.classList.remove('-modal-active')
}