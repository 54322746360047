import React from "react"
import QRCodeChina from "../images/icons/QR_Code_China.jpeg"

export default () => (
  <div className="menu-button -blue-gradient shop-now-toggle">
    <div className="shop-now__background"></div>
    <div className="js-shop-now" onClick={toggleShopNow}></div>
    <span className="shop-now-toggle__button-text">Shop Now</span>

    <div className="shop-now__inner">
      <p>
        <i className="fas fa-map-marked-alt"></i>
        <br />
        Choose your
        <br />
        <strong>shipping destination</strong>
      </p>
      <span className="help-text">Scroll to find your shipping destination</span>

      <div className="shipping-destinations">
        <div className="shipping-destinations__item">
          <input type="radio" id="destChina" name="destinations" value={QRCodeChina} defaultChecked onChange={showQRCode} />
          <label htmlFor="destChina">Mainland China, Greater China</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destUS" name="destinations" value="http://bit.ly/35gYLTM" onChange={updateCta} />
          <label htmlFor="destUS">US</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destCanada" name="destinations" value="http://bit.ly/2qqFpvH" onChange={updateCta} />
          <label htmlFor="destCanada">Canada</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destHongKong" name="destinations" value="http://bit.ly/2M9ESXw" onChange={updateCta} />
          <label htmlFor="destHongKong">Hong Kong, Greater China</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destTaiwan" name="destinations" value="http://bit.ly/2MAkeP7" onChange={updateCta} />
          <label htmlFor="destTaiwan">Taiwan, Greater China</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destSingapore" name="destinations" value="http://bit.ly/2oFZE7Y" onChange={updateCta} />
          <label htmlFor="destSingapore">Singapore</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destMalaysia" name="destinations" value="http://bit.ly/2IJuBz0" onChange={updateCta} />
          <label htmlFor="destMalaysia">Malaysia</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destIndonesia" name="destinations" value="http://bit.ly/32bCZz5" onChange={updateCta} />
          <label htmlFor="destIndonesia">Indonesia</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destThailand" name="destinations" value="http://bit.ly/2B54WN2" onChange={updateCta} />
          <label htmlFor="destThailand">Thailand</label>
        </div>
        <div className="shipping-destinations__item">
          <input type="radio" id="destIndia" name="destinations" value="http://bit.ly/2rmm7YV" onChange={updateCta} />
          <label htmlFor="destIndia">India</label>
        </div>
      </div>

      <span className="help-text -mainland-china-text">Airport exclusive discount at checkout*</span>
      <span className="help-text -other-countries">
        Airport exclusive discount at checkout*
        <br />
        Enter promo code: SCHIPHOL5
      </span>

      <a href="http://bit.ly/35gYLTM" target="_blank" rel="noopener noreferrer" className="cta shop-now-cta -gold-gradient">Continue</a>
      <span className="help-text -continue-text">
        You will be redirected to a site that may not be intended for residents of this country.
        <br />
        <br />
        <span>*On select products only, applied at checkout. Excludes infant formula and medical food in most countries</span>
      </span>

      <div className="china-qr-code">
        <span>Please use your WeChat app to scan and go to the store</span>
        <img src={QRCodeChina} alt="" />
        <span>*On select products only, applied at checkout. Excludes infant formula and medical food in most countries</span>
      </div>
    </div>
  </div>
)

function toggleShopNow(e) {
  document.getElementsByClassName('shop-now-toggle')[0].classList.toggle('-open')
  document.body.classList.toggle('-shop-now-active')
}

function updateCta(event) {
  let el = event.target
  document.getElementsByClassName('shop-now-cta')[0].setAttribute('href', el.value)
  document.querySelector('.china-qr-code').style.display = "none"  
  document.querySelector('.help-text.-mainland-china-text').style.display = "none"  
  document.querySelector('.help-text.-continue-text').style.display = "block" 
  document.querySelector('.help-text.-other-countries').style.display = "block" 
  document.getElementsByClassName('shop-now-cta')[0].style.display = "inline-block"
}

function showQRCode() {
  document.getElementsByClassName('shop-now-cta')[0].style.display = "none"
  document.querySelector('.china-qr-code').style.display = "block"
  document.querySelector('.help-text.-mainland-china-text').style.display = "block"  
  document.querySelector('.help-text.-continue-text').style.display = "none"  
  document.querySelector('.help-text.-other-countries').style.display = "none"  
}